import React, { useEffect } from 'react';
import SplitType from 'split-type';
import gsap from 'gsap';
import './Preloader.css'; 

const Preloader = ({ onComplete }) => {
  useEffect(() => {
    const loadingText = new SplitType('.loading-text.initial', { types: 'chars' });
    const completeText = new SplitType('.loading-text.complete', { types: 'chars' });
    document.body.style.overflow = "hidden";

    const tl = gsap.timeline({
      onComplete: () => {
        if (onComplete) onComplete();
      },
    });

    tl.to('.progress-bar', {
      width: '100%',
      duration: 5,
      ease: 'power1.inOut',
      onUpdate: function () {
        const progress = Math.round(this.progress() * 100);
        document.querySelector('.percentage').textContent = progress;
      },
    })
      .to('.loading-text.initial', { y: '-100%', duration: 0.5, ease: 'power2.inOut' })
      .to('.loading-text.complete', { y: '0%', duration: 0.5, ease: 'power2.inOut' }, '<')
      .to('.preloader', { y: '-100vh', duration: 1, ease: 'power2.inOut' })
      .set('.preloader', { display: 'none' });

    return () => {
      gsap.killTweensOf(tl);
    };
  }, [onComplete]);

  return (
    <div className="preloader fixed inset-0 bg-[#0e1a22] flex justify-center items-center flex-col z-50">
      <div className="progress-container w-[300px] h-[2px] bg-white/10 mb-5 relative z-20">
        <div className="progress-bar absolute left-0 top-0 h-full bg-[#e6e1d7] w-0"></div>
      </div>
      <div className="text-container relative overflow-hidden my-5 w-[200px] h-[3em]">
        <div className="loading-text initial absolute w-full text-center text-[#e6e1d7] font-[300] uppercase text-base leading-tight tracking-tight">
          Loading...
        </div>
        <div className="loading-text complete absolute w-full text-center text-[#e6e1d7] font-[300] uppercase text-base leading-tight tracking-tight">
          Welcome
        </div>
      </div>
      <div className="percentage fixed bottom-8 right-8 text-[#e6e1d7] opacity-10 text-[25rem] font-bold">0</div>
    </div>
  );
};

export default Preloader;
