import React, { useEffect, useState } from 'react';
import Preloader from './components/Preloader';
import Banner from './components/Banner';
import Header from './components/Header';
import Nav from './components/Nav';
import About from './components/About';
import Services from './components/Services';
import Work from './components/Work';
import Contact from './components/Contact';
import Recommendation from './components/Recomendation';
import Footer from './components/Footer';

const App = () => {
  const [loading, setLoading] = useState(true);

  const handlePreloaderComplete = () => {
    setLoading(false);
  };

  useEffect(() => {
    // Reset scrolling behavior
    if (!loading) {
      document.body.style.overflow = 'auto';
    }
  }, [loading]);

  return (
    <div className="bg-site bg-no-repeat bg-cover">
      {loading && <Preloader onComplete={handlePreloaderComplete} />}
      {!loading && (
        <>
          <Header />
          <Banner />
          <Nav />
          <About />
          <Services />
          <Work />
          <div className="my-8">
            <Recommendation />
          </div>
          <Contact />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
